//eslint-disable-next-line import/no-unresolved
import 'vite/modulepreload-polyfill';

import '../js/leaflet.js';
import '../scss/app.scss';
import '../scss/main.scss';
import App from './App.vue';
import VtDataTable from './admin/components/tables/core/VtDataTable.vue';
import VtGenericFilter from './admin/components/tables/core/VtGenericFilter.vue';
import VtPerPageSelector from './admin/components/tables/core/VtPerPageSelector.vue';
import navigationGuard from './router/navigationGuard.js';
import cyDirective from './shared/directives/v-cy.js';
import translation from './shared/mixins/translation.js';
import store from './store';
import {autoAnimatePlugin} from '@formkit/auto-animate/vue';
import router from './router/router.js';
import * as Sentry from '@sentry/vue';
import VCalendar, {DatePicker} from 'v-calendar';
import {ServerTable} from 'v-tables-3';
import {createApp} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import VueFinalModal from 'vue-final-modal';
import msalPlugin from './shared/plugins/msalPlugin';
import {msalInstance} from './api/authentication/msalAuthConfig';
import FloatingVue from 'floating-vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import useDrilldown from 'highcharts/modules/drilldown';
import {globalOptions} from './shared/mixins/chartsHelper';
import {createPinia} from 'pinia';
import PrimeVue from 'primevue/config';
import PrimeVuePreset from './shared/primevue';
import ToastService from 'primevue/toastservice';
import {trackMatomoPageView} from './shared/matomoTracking';

import.meta.glob(['../images/**']);

const app = createApp(App, {
    flashes: document.getElementById('app').getAttribute('data-flashes'),
});

const sentryExceptions = [
    /failed to fetch/i,
    /load failed/i,
    /networkerror when attempting to fetch resource/i,
    /\(reading '_leaflet_pos'\)/i,
    /\(evaluating 'h._leaflet_pos'\)/i,
];

const stackTraceSentryExceptions = [/_getMapPanePos/];

/* eslint-disable no-undef, no-console */
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN_JS,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [
        Sentry.browserTracingIntegration({router}),
        Sentry.extraErrorDataIntegration({
            depth: 3,
        }),
    ],
    logErrors: true,
    trackComponents: true,
    tracesSampleRate: process.env.NODE_ENV === 'development',
    hooks: ['create', 'mount', 'update', 'activate', 'destroy'],

    beforeSend(event, hint) {
        const error = hint?.originalException;

        if (!error) {
            return event;
        }

        if ([400, 401, 403, 404, 500].includes(error.status)) {
            return null;
        }

        const message = typeof error === 'string' ? error : error.message;

        if (typeof message !== 'string') {
            event.fingerprint = [JSON.stringify(event), JSON.stringify(hint)];

            return event;
        }

        if (sentryExceptions.some(regex => new RegExp(regex).test(message))) {
            return null;
        }
        function getStackTrace() {
            try {
                return event.exception?.values
                    ?.map(exception =>
                        exception.stacktrace.frames?.map(frame => frame.function)?.join('')
                    )
                    ?.filter(Boolean)
                    ?.join('');
            } catch {
                return null;
            }
        }

        const stackTrace = getStackTrace();

        if (stackTrace && stackTraceSentryExceptions.some(regex => regex.test(stackTrace))) {
            return null;
        }

        return event;
    },
});

useDrilldown(Highcharts);
Highcharts.setOptions(globalOptions);

const floatingVueOptions = {
    themes: {
        tooltip: {
            distance: 10,
            delay: {
                show: 450,
                hide: 0,
            },
        },
    },
};

const pinia = createPinia();
app.use(pinia)
    .use(router)
    .use(store)
    .use(VueFinalModal())
    .use(ServerTable, {}, 'tailwind', {
        dataTable: VtDataTable,
        genericFilter: VtGenericFilter,
        perPageSelector: VtPerPageSelector,
    })
    .use(VCalendar)
    .use(VueApexCharts)
    .use(autoAnimatePlugin)
    .use(msalPlugin, msalInstance)
    .use('VDatePicker', DatePicker)
    .use(HighchartsVue, {Highcharts, tagName: 'customChart'})
    .use(PrimeVue, {
        unstyled: true,
        pt: PrimeVuePreset,
    })
    .use(ToastService)
    .use(FloatingVue, floatingVueOptions)
    .directive('cy', cyDirective)
    .mixin(translation)
    .mount('#app');

app.config.errorHandler = (err, instance, info) => {
    console.groupCollapsed('An error has occurred');
    console.log(info);
    console.log(err);
    if (import.meta.env.DEV) {
        console.group('Trace');
        let parent = instance;
        do {
            const type = parent?.$?.type;

            if (!type) {
                break;
            }

            console.dir(type);
        } while ((parent = parent.$parent));
        console.groupEnd('Trace');
    }
    console.groupEnd('An error has occurred:');
};

router.beforeEach(navigationGuard);
router.afterEach(() => {
    trackMatomoPageView();
});

/* eslint-enable no-undef, no-console */
